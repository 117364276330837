
import { Injectable } from "@angular/core";
import * as signalR from "@microsoft/signalr";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
@Injectable()

export class SignalrService {
    hubUrl: string;
    connection: any;
    progressPercentage: BehaviorSubject<ProgressPercentage>;
    progressErrorMessage: BehaviorSubject<ProgressErrorMessage>;
    progressCheckingMessage: BehaviorSubject<ProgressCheckingMessage>;
    constructor() {
        this.hubUrl = `${environment.api}${environment.signalR.hub}`;
        this.progressPercentage = new BehaviorSubject<ProgressPercentage>({ conection: '', percentage: 0 });
        this.progressErrorMessage = new BehaviorSubject<ProgressErrorMessage>({ conection: '', message: '' });
        this.progressCheckingMessage = new BehaviorSubject<ProgressCheckingMessage>({ conection: '', message: '', finished: false });
    }

    public async startConnection(): Promise<void> {
        try {
            this.connection = new signalR.HubConnectionBuilder()
                .withUrl(this.hubUrl)
                .withAutomaticReconnect()
                .build();
            await this.connection.start();
            this.setClientMethods();
        }
        catch (error) {
        }
    }

    public closeConnection() {
        if (this.connection) {
            this.connection.stop();
            this.connection = undefined;
        }
    }

    public getConnectionId(): string {
        return this.connection ? this.connection.connectionId : "";
    }
    private setClientMethods(): void {
        this.connection.on(environment.signalR.receivePercentage, (percentage: number, connectionId: string) => {
            if (connectionId == this.connection.connectionId) {
                this.progressPercentage.next({ conection: connectionId, percentage: percentage });
            }
        });

        this.connection.on(environment.signalR.receiveError, (message: string, connectionId: string) => {
            if (connectionId == this.connection.connectionId && message)
                this.progressErrorMessage.next({ conection: connectionId, message: message });
        });
        this.connection.on(environment.signalR.receiveChecking, (message: string, finished: boolean, connectionId: string) => {
            if (connectionId == this.connection.connectionId && message)
                this.progressCheckingMessage.next({ conection: connectionId, message: message, finished: finished });
        });
    }
}
export class ProgressPercentage {
    conection: string;
    percentage: number;
}

export class ProgressErrorMessage {
    conection: string;
    message: any;
}

export class ProgressCheckingMessage {
    conection: string;
    message: string;
    finished: boolean;
}