export const environment = {
  appVersion: require('../../package.json').version,
  production: false,
  api: 'https://dev.api.panel.carreirasgalegas.com',
  storage:
  {
    basicUrl: 'https://racesdev.blob.core.windows.net',
    circuits: "circuits",
    competitions: "competitions",
    regulations: "regulations",
    sponsors: "sponsors",
    races: "races",
    news: "news",
    mailings: "mailings",
    documents: "documents",
    resultsPdf: "results-pdf",
    bibsImages: "bibs-images",
    bibsDocuments: "bibs-documents",
  },
  firebase: {
    apiKey: "AIzaSyAjoRvF4dqdLdmfgndkMsvHMhJOnXIkYtI",
    authDomain: "racesdev.firebaseapp.com",
    projectId: "racesdev",
    storageBucket: "racesdev.appspot.com",
    messagingSenderId: "475683178110",
    appId: "1:475683178110:web:2ec203c9f127fd026dfa2f"
  },
  useEmulators: false,
  numberItemsBatch: 2,
  signalR:
  {
    hub: "/races-hub",
    receivePercentage: "receivePercentage",
    receiveError: "receiveError",
    receiveChecking: "receiveChecking"
  }
};
