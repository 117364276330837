import { Component } from '@angular/core';
import { AppComponent } from '../../app.component';
import { AppMainComponent } from '../../app.main.component';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { getInitials } from 'src/app/core/functions';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls: ['./app.topbar.component.scss']
})
export class AppTopbarComponent {

    user: any;
    avatar: string;
    displayName: string;

    constructor(public app: AppComponent, public appMain: AppMainComponent, public afAuth: AngularFireAuth,) {
        this.afAuth.authState.subscribe((user) => {
            this.user = user;
            this.avatar = getInitials(this.user);
        });
    }
}
