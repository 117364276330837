import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    menuMode = 'sidebar';

    layout = 'blue';

    theme = 'blue';

    ripple: boolean;

    colorScheme = 'dark';

    constructor(private primengConfig: PrimeNGConfig,
        private translateService: TranslateService,
        public afAuth: AngularFireAuth) {
        this.translateService.setDefaultLang('es-ES');
    }

    ngOnInit() {
        this.primengConfig.ripple = true;
        this.translate("es-ES");
        this.ripple = true;
        if (environment.useEmulators)
            this.afAuth.useEmulator('http://localhost:9099');
    }

    translate(lang: string) {
        this.translateService.use(lang);
        this.translateService.get('primeng').subscribe(res => this.primengConfig.setTranslation(res));
    }
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}
