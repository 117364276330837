import { Component, OnInit } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AppComponent } from 'src/app/app.component';
import { AppMainComponent } from 'src/app/app.main.component';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

  public model: any[];

  isAuthenticated: boolean;

  constructor(public app: AppComponent, public appMain: AppMainComponent,
    private permissionsService: NgxPermissionsService) { }

  ngOnInit() {

    this.model = [
      {
        label: 'Dashboard', icon: 'pi pi-fw pi-th-large', routerLink: ['/'],
        show: true
      }, {
        label: 'Eventos',
        icon: 'pi pi-fw pi-calendar',
        routerLink: ['/events'],
        show: true
      }, {
        label: 'Circuitos',
        icon: 'pi pi-fw pi-sitemap',
        routerLink: ['/circuits'],
        show: true
      },
      {
        label: 'Inscritos',
        icon: 'pi pi-fw pi-tags',
        routerLink: ['/enrollments'],
        show: true
      }, {
        label: 'Corredores',
        iconFamily: 'fontawesome',
        icon: 'person-running',
        routerLink: ['/runners'],
        show: true
      },
      {
        label: 'Clubs',
        iconFamily: 'fontawesome',
        icon: 'users',
        routerLink: ['/clubs'],
        show: true
      },
      {
        label: 'Patrocinadores',
        icon: 'pi pi-fw pi-money-bill',
        routerLink: ['/sponsors'],
        show: true
      }, {
        label: 'Categorias',
        icon: 'pi pi-fw pi-list',
        routerLink: ['/categories'],
        show: true
      }
      , {
        label: 'Configuraciones',
        icon: 'pi pi-fw pi-cog',
        routerLink: ['/configurations'],
        show: true
      }, {
        label: 'Noticias',
        icon: 'pi pi-fw pi-book',
        routerLink: ['/news'],
        show: true
      }
      , {
        label: 'Envíos',
        icon: 'pi pi-fw pi-send',
        routerLink: ['/mailings'],
        show: true
      }
      , {
        label: 'Documentos',
        icon: 'pi pi-fw pi-book',
        routerLink: ['/documents'],
        show: true
      }
      , {
        label: 'Dorsal 0',
        icon: 'pi pi-fw pi-credit-card',
        routerLink: ['/bib0s'],
        show: true
      }
      , {
        label: 'Usuarios',
        icon: 'pi pi-fw pi-user',
        routerLink: ['/users'],
        show: true
      }
    ];
  }
}
