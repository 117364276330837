import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AngularFireAuthGuard, hasCustomClaim, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/compat/auth-guard';

const adminOnly = () => hasCustomClaim('admin');
const redirectUnauthorizedToSignIn = () => redirectUnauthorizedTo(['sign-in']);
const redirectLoggedInToDashboard = () => redirectLoggedInTo(['dashboard']);
const belongsToAccount = (next) => hasCustomClaim(`account-${next.params.id}`);

/*export const routes: Routes = [
  { path: '', component: AppComponent },
  { path: 'login', component: LoginComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToDashboard } },
  { path: 'items', component: ItemListComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToSignIn } },
  { path: 'admin', component: AdminComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: adminOnly } },
  { path: 'accounts/:id', component: AdminComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: belongsToAccount } }
];*/

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('src/app/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToDashboard },
  },
  {
    path: 'dashboard',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/dashboard/dashboard.module')
        .then(m => m.DashboardModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToSignIn },
  },
  {
    path: 'categories',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/categories/categories.module')
        .then(m => m.CategoriesModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToSignIn },
  }, {
    path: 'sponsors',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/sponsors/sponsors.module')
        .then(m => m.SponsorsModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToSignIn },
  }, {
    path: 'circuits',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/circuits/circuits.module')
        .then(m => m.CircuitsModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToSignIn },
  }, {
    path: 'events',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/competitions/competitions.module')
        .then(m => m.CompetitionsModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToSignIn },
  }, {
    path: 'enrollments',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/enrollments/enrollments.module')
        .then(m => m.EnrollmentsModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToSignIn },
  },
  {
    path: 'runners',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/runners/runners.module')
        .then(m => m.RunnersModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToSignIn },
  },
  {
    path: 'configurations',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/configurations/configurations.module')
        .then(m => m.ConfigurationsModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToSignIn },
  },
  {
    path: 'news',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/news/news.module')
        .then(m => m.NewsModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToSignIn },
  },
  {
    path: 'mailings',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/mailings/mailings.module')
        .then(m => m.MailingsModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToSignIn },
  },
  {
    path: 'bib0s',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/bib0s/bib0s.module')
        .then(m => m.Bib0sModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToSignIn },
  },
  {
    path: 'documents',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/documents/documents.module')
        .then(m => m.DocumentsModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToSignIn },
  },
  {
    path: 'users',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/users/users.module')
        .then(m => m.UsersModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToSignIn },
  },
  {
    path: 'clubs',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/clubs/clubs.module')
        .then(m => m.ClubsModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToSignIn },
  },
  {
    path: 'error',
    component: AppErrorComponent
  },
  { path: 'access', component: AppAccessdeniedComponent },
  { path: 'notfound', component: AppNotfoundComponent },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
