<div class="layout-menu-wrapper" [ngClass]="{'layout-sidebar-active': appMain.sidebarActive}"
    (click)="appMain.onSidebarClick($event)" (mouseover)="appMain.onSidebarMouseOver($event)"
    (mouseleave)="appMain.onSidebarMouseLeave($event)">
    <div class="menu-logo" *ngIf="!appMain.isHorizontal() || appMain.isMobile()">
        <a href="/" class="app-name" *ngIf="!appMain.isSlim() || appMain.isMobile()">
            <img alt="Carreiras Galegas" [src]="'assets/layout/images/cg-'+ app.colorScheme + '.svg'" />
        </a>
        <a href="/" class="menu-pin" (click)="appMain.onToggleMenu($event)">
            <span *ngIf="appMain.isOverlay()" class="pi pi-times"></span>
            <span *ngIf="appMain.isSidebar() && !appMain.sidebarStatic && appMain.pinActive"
                class="pi pi-unlock"></span>
            <span *ngIf="appMain.isSidebar() && appMain.sidebarStatic && appMain.pinActive" class="pi pi-lock"></span>
        </a>
    </div>
    <div class="layout-menu-container">
        <ul class="layout-menu">
            <ng-container *ngFor="let item of model; let i = index;">
                <ng-container *ngxPermissionsOnly="item.roles">
                    <li app-menuitem *ngIf="!item.separator && item.show" [item]="item" [index]="i" [root]="true"></li>
                    <li *ngIf="item.separator" class="menu-separator"></li>
                </ng-container>
            </ng-container>
        </ul>
    </div>
    <app-inlinemenu></app-inlinemenu>
</div>