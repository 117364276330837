import { FormArray, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { User } from "firebase/auth";
import * as moment from "moment";

export function validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control instanceof UntypedFormControl) {
            control.markAsTouched({ onlySelf: true });
            control.markAsDirty({ onlySelf: true });
        } else if (control instanceof UntypedFormGroup) {
            validateAllFormFields(control);
        } else if (control instanceof FormArray) {
            control.controls.forEach(item => {
                if (item instanceof UntypedFormControl) {
                    item.markAsTouched({ onlySelf: true });
                    item.markAsDirty({ onlySelf: true });
                }
                else {
                    let itemGroup: UntypedFormGroup = item as UntypedFormGroup;
                    validateAllFormFields(itemGroup);
                }
            });
        }
    });
}


/******** Fechas ***********/
//Obtiene la hora local
export function getLocalDate(date) {
    return moment(date).local().toDate();
}

export function toDateOnlyString(date: Date) {
    if (!date)
        return null;
    else
        return `${date.getFullYear()}-${returnMonth()}-${returnDay()}`;

    function returnDay() {
        let d = (date.getDate() < 10) ? "0" + (date.getDate()) : date.getDate();
        return d;
    }
    function returnMonth() {
        let m = (date.getMonth() + 1 < 10) ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
        return m;
    }
}

export function formatDate(inputDate: Date) {
    let date, month, year;

    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();

    date = date
        .toString()
        .padStart(2, '0');

    month = month
        .toString()
        .padStart(2, '0');

    return `${date}/${month}/${year}`;
}


export function checkStringDate(date: string, dateFormat: string): boolean {

    let newDate = moment(date, dateFormat);
    return newDate.isValid() && (moment(newDate).format(dateFormat) == date);

}
export function stringToDate(date: string, dateFormat: string): Date {

    let newDate = moment(date, dateFormat);
    return newDate.startOf("day").toDate();

}


/******** Tiempo ***********/
export function checkStringTime(time: string): boolean {
    let result = false;
    if (time && time.indexOf(":") > -1) {
        let timeParts = time.replace(/\s/g, "").split(":");

        if (timeParts.length < 3)
            return false;

        let hours = Number(timeParts[0]);
        let minutes = Number(timeParts[1]);
        let seconds = Number(timeParts[2]);
        result = !isNaN(hours)
            && !isNaN(minutes) && minutes <= 59
            && !isNaN(seconds) && seconds <= 59;
    }

    return result;
}

export function formatStringTime(time: string): string {
    let result = null;
    if (time && time.indexOf(":") > -1) {
        let timeParts = time.replace(/\s/g, "").split(":");
        let hasError = timeParts.findIndex(f => isNaN(Number(f))) >= 0;

        if (!hasError) {

            let hours = timeParts.length == 3 ? normalizeTimePart(timeParts[0]) : "00";
            let minutes = timeParts.length == 3 ? normalizeTimePart(timeParts[1]) : normalizeTimePart(timeParts[0]);
            let seconds = timeParts.length == 3 ? normalizeTimePart(timeParts[2]) : normalizeTimePart(timeParts[1]);
            result = `${hours}:${minutes}:${seconds}`;
        }
    }

    return result;
}

function normalizeTimePart(time: string) {

    if (time.length > 2) {
        return time.substring(0, 2);
    }
    else if (time.length == 2) {
        return time;
    }
    else if (time.length == 1) {
        return `0${time}`;
    }
    else {
        return `00`;
    }

}
/***************** */
export function calculateAge(birthday) { // birthday is a date
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

/******************* */

export function removeFileExtension(fileName: string) {
    let indexExtension = fileName.lastIndexOf('.');
    if (indexExtension === -1) return fileName;
    else return fileName.substring(0, indexExtension);
}

export function getInitials(user: User) {
    let initials = "";
    let name = "";
    if (user) {
        if (user.displayName) {
            name = user.displayName;
        }
        else {
            name = user.email;
        }
    }
    let initialsArray = name.split(' ');
    if (initialsArray.length > 1) {
        initials += initialsArray[0][0] + initialsArray[1][0];
    }
    else {
        initials += initialsArray[0][0] + initialsArray[0][1];
    }
    return initials;
}

export function getDisplayName(user: User) {
    if (user.displayName) {
        return user.displayName;
    }
    else {
        return user.email;
    }
}

/********** Objetos ********* */
export function deepCopy(oldObj: any) {
    var newObj = oldObj;
    if (oldObj && typeof oldObj === "object") {
        if (oldObj instanceof Date) {
            return new Date(oldObj.getTime());
        }
        newObj = Object.prototype.toString.call(oldObj) === "[object Array]" ? [] : {};
        for (var i in oldObj) {
            newObj[i] = deepCopy(oldObj[i]);
        }
    }
    return newObj;
}

/***************** */
export function normalizeName(name: string) {
    return name ? name.toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        .replace(/\s/g, "")
        .replace(/[-_\s]/gi, "")
        : name;
}

export function capitalizeName(name) {
    var splitStr = name.trim().toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitCapitalize(splitStr[i], '.');
        splitStr[i] = splitCapitalize(splitStr[i], '´');
        splitStr[i] = splitCapitalize(splitStr[i], "'");
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
}
function splitCapitalize(word: string, splitChart: string) {
    if (word) {
        var splitStr = word.split(splitChart);
        for (var j = 0; j < splitStr.length; j++) {
            splitStr[j] = splitStr[j].charAt(0).toUpperCase() + splitStr[j].substring(1);
        }
        word = splitStr.join(splitChart);
    }
    return word;
}

/******* Array ********/
export function arrayMove(array, oldIndex, newIndex) {
    if (newIndex >= array.length) {
        var k = newIndex - array.length + 1;
        while (k--) {
            array.push(undefined);
        }
    }
    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
    return array;
};

export function insertArrayAt(array, index, arrayToInsert) {
    Array.prototype.splice.apply(array, [index, 0].concat(arrayToInsert));
    return array;
}

export function arrayChunk(array, chunkSize) {
    const result = array.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / chunkSize)
        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // start a new chunk
        }
        resultArray[chunkIndex].push(item)
        return resultArray
    }, []);

    return result;
}