import { Injectable } from "@angular/core";
import { ConfirmationService } from "primeng/api";

@Injectable()
export class ConfirmDialogService {
  constructor(
    private service: ConfirmationService
  ) { }

  public confirm(message: string, callback: Function) {
    this.service.confirm({
      key: 'confirm1',
      message: message,
      header: 'Confirmar',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        callback();
      }
    });
  }
}
