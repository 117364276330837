import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppComponent } from '../../app.component';

@Component({
    selector: 'app-footer',
    template: `
        <div class="layout-footer">
            <div class="footer-logo-container">
            </div>
            <span class="copyright">&#169; Ingeniería Gallega de Software SL - {{currentYear}} v{{currentApplicationVersion}}</span>
        </div>
    `
})
export class AppFooterComponent {

    currentApplicationVersion = environment.appVersion;
    currentYear = new Date().getFullYear();

    constructor(public app: AppComponent) { }
}
